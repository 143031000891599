import * as React from "react"
import { navigate } from "gatsby"
import Icon from '../images/svg/close.svg'

// styles
const pageStyles = {
  color: "#232129",
  padding: "2.5% 0",
  wordBreak: 'break-word',
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  textAlign: 'center',
}

// markup
const Datenschutzerklaerung = () => {
  return (
    <main style={pageStyles}>
      <Icon className="modal-close" onClick={()=>navigate('/')}/>
      <h1 style={headingStyles}>Datenschutzerklärung</h1>
      <b>Erklärung zur Informationspflicht der Website „Klasse Steira“</b><br />
      <br />
      <b>Verantwortlicher:</b><br />
      Weingut Krispel GmbH<br />
      <b>Geschäftsführer:</b> Stefan Krispel<br />
      Neusetz 29, <br />
      A-8345 Hof bei Straden<br />
      Tel: +43/3473/7862<br />
      Fax: +43/3473/7862 4<br />
      Homepage:  www.klasse-steira.at<br />
      E-Mail: office@krispel.at<br />
      <br />
      <br />
      Es ist kein Datenschutzbeauftragter bestellt, da dies gesetzlich nicht notwendig ist.<br />
      <br />
      <b>Allgemeines</b><br />
      Der Schutz Ihrer persönlichen Daten ist uns ein besonderes Anliegen. Wir verarbeiten Ihre Daten daher ausschließlich auf Grundlage der gesetzlichen Bestimmungen (DSGVO, TKG 2003). In diesen Datenschutzinformationen informieren wir Sie über die wichtigsten Aspekte der Datenverarbeitung im Rahmen unserer Website.<br />
      <br />
      Als Nutzer stimmen Sie der Datenverarbeitung im Sinne dieser Erklärung zu. Zur aktuellen Fassung der DSG VO kommen Sie unter <a href="http://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&from=DE" target="_blank" rel="noreferrer">http://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&from=DE</a>.<br />
      Die Datenschutzerklärung bezieht sich nur auf diese Praxis und die damit in Verbindung stehende Website und Online-Angebote. Falls Sie über Links auf unseren Seiten auf andere Seiten weitergeleitet werden, informieren Sie sich direkt auf der weitergeleiteten Webseite über den jeweiligen Umgang mit Ihren Daten. Ihre personenbezogenen Daten (z.B. Name, E-Mail-Adresse, Telefonnummer, IP-Adresse) werden von uns nur gemäß den Bestimmungen des österreichischen und europäischen Datenschutzrechts verarbeitet. Bei Aufruf unserer Webseite werden Informationen, wie zB Ihre IP-Adresse, an uns übertragen. Diese Informationen geben Auskunft über das genutzte Endgerät (Computer, Smartphone, Tablet etc), den verwendeten Browser (Internetexplorer, Safari, Firefox, Chrome etc), den Zeitpunkt des Zugriffs auf unsere Website, die übertragenen Datenmengen und Ähnliches. Diese Daten werden von uns nicht dazu verwendet, den einzelnen Nutzer zu identifizieren. Die Informationen dienen lediglich dazu, die Attraktivität unserer Webseite zu ermitteln, und deren Inhalte laufend zu verbessern und für Sie noch interessanter zu gestalten.<br />
      <br />
      Die Nutzung der Webseite ist in der Regel ohne aktive Angabe personenbezogener Daten möglich. Soweit auf unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder E-Mail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben.<br />
      <br />
      Die Verarbeitung personenbezogener Daten erfolgt nur unter Einhaltung der einschlägigen Datenschutzbestimmungen und werden nur bei Vorliegen einer gesetzlichen Erlaubnis oder Einwilligung verarbeitet. Als gesetzliche Erlaubnis gilt unter anderem die Verarbeitung im Rahmen der Erbringung unserer vertraglichen Leistungen (z.B. Bearbeitung von Anfragen, Aufträgen). Die Verarbeitung darf aber auch ohne Einwilligung aufgrund unserer berechtigten Interessen, wie etwa das Interesse an der Analyse, Optimierung und wirtschaftlichem Betrieb und Sicherheit unserer Leistungen und Angebote im Sinne des Art. 6 Abs. 1 lit. f. DSGVO, insbesondere für die Erstellung von Profilen für Werbe- und Marketingzwecken sowie der Erhebung von Zugriffsdaten und Einsatz der Dienste von Drittanbietern erfolgen.<br />
      <br />
      <b>Die Rechtsgrundlage</b> für die von uns vorgenommene Verarbeitung der Kundendaten ergibt sich damit:<br />
      <br />
      • aus einer Einwilligungen gem. Art. 6 Abs. 1 lit. a. und Art. 7 DSGVO<br />
      <br />
      • aus Art. 6 Abs. 1 lit. b. DSGVO für die Verarbeitung zur Erfüllung unserer Leistungen und Durchführung vertraglicher Maßnahmen (etwa Information)<br />
      <br />
      • aus Art. 6 Abs. 1 lit. c. DSGVO für die Verarbeitung zur Erfüllung unserer rechtlichen Verpflichtungen,<br />
      <br />
      • aus Art. 6 Abs. 1 lit. f. DSGVO für die Verarbeitung zur Wahrung unserer berechtigten Interessen.<br />
      <br />
      Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail, oder über nicht verschlüsselte Webseiten) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.<br />
      <br />
      Bei Beauftragung über unsere Webseite werden Ihre übermittelten oder eingegebenen Daten manuell oder automatisiert mit dem vorhandenen Datenbestand auf Gültigkeit und Aktualität überprüft und gegebenenfalls abgeglichen, um die bei uns gespeicherten Daten zu korrigieren. Dazu kann es notwendig sein, dass wir mit Ihnen Kontakt aufnehmen müssen. Dies geschieht mittels E-Mail oder telefonisch. Die von Ihnen eingegebenen Daten werden ausschließlich für die Bearbeitung der Anfragen oder die Abwicklung möglicher Bestellungen von Werbemitteln verwendet, eine Weitergabe der Daten an Dritte erfolgt nicht.<br />
      <br />
      Betroffenenrechte<br />
      Gemäß der Datenschutz-Grundverordnung und dem Datenschutzgesetz stehen Ihnen als betroffene Person der Datenverarbeitung folgende Rechte und Rechtsbehelfe zu:<br />
      <br />
      • Recht auf Auskunft<br />
      <br />
      • Recht auf Berichtigung und Löschung<br />
      <br />
      Fällt die Zweckbestimmung weg und gibt es keine gesetzlichen Aufbewahrungspflichten mehr und andere berechtigte Interessen liegen nicht vor, werden die gespeicherten Daten gelöscht. Sind Daten nur noch wegen der gesetzlichen Aufbewahrungspflicht (aus steuerrechtlichen, wirtschaftlichen oder urheberrechtlichen Gründen) gespeichert, schränken wir die Verarbeitung durch Sperren der Daten ein. Gesetzliche/rechtliche Aufbewahrungspflichten oder vertragliche Verpflichtungen z.B. gegenüber Kunden aus Gewährleistung oder Schadenersatz oder gegenüber Vertragspartnern sind eine Grundlage die personenbezogenen Daten weiterhin zu speichern. (Art 6 Abs 1 lit c DSGVO <br />
      <br />
      <b>Grundlage der Rechtmäßigkeit der Verarbeitung:</b> gesetzliche / rechtliche Verpflichtung)<br />
      <br />
      • Recht auf Einschränkung der Verarbeitung<br />
      <br />
      • Recht auf Datenübertragbarkeit an einen Dritten<br />
      <br />
      • Widerspruchsrecht gegen die Verarbeitung<br />
      <br />
      Ihre Daten werden nach Widerspruch nicht mehr verarbeitet, es sei denn, es liegen zwingende schutzwürdige Gründe für die Verarbeitung vor, die Ihre Interessen, Rechte und Freiheiten überwiegen oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen. Anfragen und die Geltendmachung Ihrer Rechte können Sie jederzeit kostenfrei an folgende verantwortliche Stelle richten:<br />
      <br />
      Weingut Krispel GmbH<br />
      Geschäftsführer: Stefan Krispel<br />
      Neusetz 29, <br />
      A-8345 Hof bei Straden<br />
      Tel: +43/3473/7862<br />
      Fax: +43/3473/7862 4<br />
      Homepage:  www.klasse-steira.at<br />
      E-Mail: office@krispel.at<br />
      <br />
      Für Anträge betreffend Verletzung des Rechtes auf Auskunft, Verletzung der Rechte auf Geheimhaltung, auf Richtigstellung oder auf Löschung ist die Österreichische Datenschutzbehörde in der Wickenburggasse 8, in 1080 Wien, zuständig. <a href="https://www.dsb.gv.at/ueber-die-website/kontakt.html" target="_blank" rel="noreferrer">Zum Kontakt </a><br />
      <br />
      <b>Links</b><br />
      Wir überprüfen Links, die unsere Webseite verlassen, redaktionell sehr sorgfältig. Trotzdem übernehmen wir für Inhalte auf Seiten, auf die von dieser Webseite oder von einem anderen Internetauftritt aus gelinkt wird, keinerlei Verantwortung oder Haftung.<br />
      <br />
      <b>Informationen über Cookies</b><br />
      Cookies sind Dateien, die auf Ihrem lokalen Endgerät (PC, Laptop, Smartphone) gespeichert werden und Informationen über Sie speichern, auf die die jeweiligen Verantwortlichen (und Dritte) für die Dauer der Gültigkeit der Cookies zugreifen können. Der Hinweis über die Cookieverwendung erscheint beim ersten Öffnen der Website. Sie haben die Möglichkeit deren Verwendung zuzustimmen oder nicht zuzustimmen.<br />
      <br />
      Stimmen Sie der Cookieverwendung zu, werden neben den technisch notwendigen Cookies auch die nicht notwendigen Cookies zur Erhebung spezieller personenbezogener Benutzerdaten über das Verhalten von Menschen auf einer Website verwendet. Stimmen Sie nicht zu, dann werden nur die technisch notwendigen Cookies verwendet.<br />
      <br />
      <b>Notwendige Cookies</b><br />
      <br />
      Notwendige Cookies sind für das reibungslose Funktionieren der Website unbedingt erforderlich. Diese Kategorie enthält nur Cookies, die grundlegende Funktionen und Sicherheitsmerkmale der Website gewährleisten. Diese Cookies speichern keine persönlichen Informationen. Dazu gehören Session Cookies, die automatisch angelegt werden und welche die Sicherheit in der Kommunikation zwischen Server und Client erhöhen (werden nach der Sitzung gelöscht), sowie die Cookie-Einstellungen selbst, die die gesetzten Cookie-Einstellungen speichern. Eine weitere Kategorie sind Funktionelle Cookies, die Sie über Ihren Browser deaktivieren können. Sie beeinflussen aber gewisse Funktionen wie zum Beispiel den Aufruf geschützter Seiten (Login-Handling) oder auch das einwandfreie Funktionieren eines Web-Shops.<br />
      <br />
      <b>Nicht notwendige Cookies</b><br />
      <br />
      Alle Cookies, die für das Funktionieren der Website nicht unbedingt erforderlich sind und die speziell zum Sammeln personenbezogener Benutzerdaten über Analysen, Anzeigen und andere eingebettete Inhalte verwendet werden, werden als nicht erforderliche Cookies bezeichnet. Etwa zielorientierte Cookies (z.B. Google Analytics), Marketing Cookies (Auslieferung zielgerichteter Werbung) oder Social Media Widgets (Laden von Bildern, Videos direkt vom Social Media Anbieter, was zum Austausch von Daten mit diesem Netzwerk führt). Dafür ist eine zwingend notwendige Zustimmung von Ihnen erforderlich. Werden diese Cookies deaktiviert (Sie stimmen der Verwendung nicht zu), kann sich dies auf Ihr Surferlebnis auswirken.<br />
      <br />
      <b>Google Analytics</b><br />
      Da wir berechtigte Interessen an der Analyse der Kundendaten haben, verwenden wir Google Analytics. Anbieter ist die Google Inc., 1600 Amphitheatre Parkway Mountain View, CA 94043, USA, wobei Google Cookies verwendet. Die Informationen, die durch Cookies erzeugt werden, gelangen zu einem Server von Google in den USA und werden dort gespeichert. Google weist eine Zertifizierung auf (Privacy-Shield-Abkommen), wodurch die Einhaltung des europäischen Datenschutzrechtes gewährt wird.<br />
      Dies finden Sie unter folgendem Link: <a href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active" target="_blank" rel="noreferrer">https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active</a>.<br />
      <br />
      Die erlangten Informationen werden von Google in unserem Auftrag zur Auswertung verwendet. Damit werden Berichte über die Aktivitäten innerhalb unseres Angebotes zusammengestellt. Der Einsatz von Google Analytics erfolgt nur mit aktivierter IP-Anonymisierung (gekürzte IP-Adresse). Nur in Ausnahmefällen wird die Kürzung der IP-Adresse von Google selbst übernommen. Die übermittelte IP-Adresse vom Browser des Kunden wird nicht mit anderen Daten von Google zusammengeführt. Umfangreiche Informationen zur Datennutzung durch Google sowie über Unterbindung und Widerspruchsmöglichkeit betreffend die Datennutzung erfahren Sie über folgende Links:<br />
      <a href="https://www.google.com/intl/de/policies/privacy/partners" target="_blank" rel="noreferrer">https://www.google.com/intl/de/policies/privacy/partners</a> <br />
      <a href="http://www.google.com/policies/technologies/ads" target="_blank" rel="noreferrer">http://www.google.com/policies/technologies/ads</a> <br />
      <a href="http://www.google.de/settings/ads" target="_blank" rel="noreferrer">http://www.google.de/settings/ads</a> <br />
      <br />
      <b>Server-Log-Files:</b><br />
      Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log Files, die Ihr Browser automatisch an uns übermittelt. Dies sind: Browsertyp und Browserversion, verwendetes Betriebssystem, Referrer URL, Hostname des zugreifenden Rechners, Uhrzeit der Serveranfrage, IP Adresse. Diese Daten sind nicht bestimmten Personen zuordenbar. Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen. Wir behalten uns vor, diese Daten nachträglich zu prüfen, wenn uns konkrete Anhaltspunkte für eine rechtswidrige Nutzung bekannt werden.<br />
      <br />
      <b>SSL-Verschlüsselung:</b><br />
      Diese Seite nutzt aus Gründen der Sicherheit und zum Schutz der Übertragung vertraulicher Inhalte, wie zum Beispiel der Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von „http://“ auf „https://“ wechselt und an dem Schloss-Symbol in Ihrer Browserzeile. Wenn die SSL Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.<br />
    </main>
  )
}

export default Datenschutzerklaerung
